body {
  background-color: #000; /* This sets the background to black */
}

.gallery {
  background-color: black;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.2rem;
  padding: 1rem;
  padding-top: 74px;
  width: 100%;
  border-radius: 0.5rem;
}

.card {
  color: whitesmoke;
  flex-direction: column;
  background: #212121;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: left;
  position: relative;
}

.nft-rank {
  position: absolute;
  bottom: 2px;
  right: 2px;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 5px 10px;
  border-radius: 0.5rem;
  font-size: 0.8em;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.nft-listed-pill {
  position: absolute;
  bottom: 35px;
  right: 2px;
  padding: 5px 10px;
  border-radius: 0.5rem;
  margin-left: 10px; /* space from the rank */
  font-size: 0.8em; /* smaller font size */
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.card:hover{
  transform: scale(1.05);
  transition: all linear 100ms;
  z-index: 1;
  box-shadow: 1px 1px 10px rgba(0,0,0,.3);
  cursor: pointer;
}

.card-image img {
  width: 100%;
  display: flex;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.card-body {
  padding: 0.5rem;
}

.card-title {
  margin: 0;
  font-weight: bold;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.card-text {
  margin: 0;
  color: #666;
}

.card-label {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  color: #333;
  font-weight: bold;
}

.container {
  display: flex;
  background-color: #121212;
}

.filter-group {
  margin: 0px;
  color: whitesmoke;
  padding: 6px 5px 0px 5px;
  font-size: 13px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.filter-group label {
  display: block;
  margin-bottom: 0.1rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.filter-group select {
  width: 100%;
  padding: 0.3rem;
  border: 3px solid whitesmoke;
  border-radius: 0.25rem;
  font-size: 14px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.sidebar {
  transition: width 0.3s ease;
  overflow: hidden;
  width: 200px; /* Adjust width as needed */
  flex-shrink: 0;
  background: #121212;
  display: flex;
  flex-direction: column;
  padding-top: 65px;
  padding-bottom: 100px;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0; /* Distance from the top of the viewport */
  height: calc(100vh - 20px); /* Adjust height as necessary, e.g., if there's a header */
  overflow-y: auto;
}

/* Responsive styles for mobile screens */
@media (max-width: 768px) { /* Adjust max-width as needed for your breakpoint */
  .sidebar {
    width: 50%; /* Sidebar takes up half the screen on mobile */
  }

  .filter-group select {
    font-size: 0.7rem; /* Smaller font size for selectors on mobile */
  }
  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.sidebar-footer {
  text-align: center; /* Align the button to the left */
}

.reset-filters-btn {
  padding: 5px 5px;
  margin: 10px 10px 0px 10px;
  background-color: #4c4c4c; /* Example button color */
  color: whitesmoke;
  border: 5px;
  cursor: pointer;
  border-radius: 20px;
  display: block;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.reset-filters-btn:hover {
  background-color: #15E7B6;
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.toggle-sidebar-btn {
  color: whitesmoke;
  display: block;
  padding: 5px 5px;
  border-radius: 20px;
  background: #4c4c4c; /* Light grey background */
  border: none; /* Remove border */
  cursor: pointer; /* Change cursor on hover */
  margin: 0px 10px 0px 10px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.toggle-sidebar-btn:hover {
  background-color: #15E7B6;
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.ones-btn {
  color: black;
  display: block;
  padding: 5px 5px;
  border-radius: 20px;
  background: #15E7B6; /* Light grey background */
  border: none; /* Remove border */
  cursor: pointer; /* Change cursor on hover */
  margin: 10px 10px 0px 10px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.ones-btn:hover {
  background-color: whitesmoke;
  color: black;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.img-fluid {
  max-height: 50px;
  margin: 0px;
}

.sort-bar {
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: #121212;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* make sure the parent container allows full width */
  padding: 10px;
  box-sizing: border-box;
}

.sort-and-count-container {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.logo-container a {
  text-decoration: none; /* Removes the underline from the link */
  /* Add other styles if needed */
}