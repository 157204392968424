.modal {
display: flex;
position: fixed;
z-index: 1;
left: 0;
top: 0;
width: 100%;
height: 100%;
overflow: hidden;
background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
background-color: #121212;
margin: auto;
padding: 0px 20px 20px 20px;
max-width: 70vh;
max-height: 100vh;
border-radius: 10px;
overflow-y: auto;
}

/* Styles for smaller devices like mobile phones */
@media (max-width: 768px) {
    .traits-section {
        gap: 5px; /* Smaller gap on mobile */
        font-size: 0.8em;
        display: flex !important;
        flex-direction: column !important;
    }

    .modal-content {
        padding: 10px; /* Reduced padding on mobile */
        width: 90%; /* Full width on mobile, with a bit of margin */
        max-width: none; /* Overwrites any max-width set for larger screens */
        max-height: 90vh; /* Prevents the modal from being too tall */
        border-radius: 5px; /* Smaller border-radius on smaller screens */
    }

    .close {
        font-size: 28px; /* Smaller close button on mobile */
        margin-top: -15px; /* Adjust as necessary */
        margin-right: -5px; /* Adjust as necessary */
    }

    .modal-body, .details-row, .details-section {
        flex-direction: column; /* Stack elements vertically on small screens */
    }

    .image-section {
        max-height: none; /* Remove max-height restriction */
        width: 100%; /* Full width images and traits-section on mobile */
    }
}

.close {
color: #aaaaaa;
font-size: 35px;
font-weight: bold;
float: right;
margin-top: -20px;
margin-right: -8px;
}

.close:hover,
.close:focus {
color: #000;
text-decoration: none;
cursor: pointer;
}

.modal-body {
display: flex;
flex-direction: row;
}

.image-section {
width: 100%;
max-height: 70vh;
max-width: 70vh;
padding-left: 0px;
display: flex;
}

.nft-image {
display: flex;
width: 100%;
max-height: 2000px;
border-radius: 10px;
}

.details-row {
display: flex;
width: 100%;
}

.details-section {
width: 100%;
display: flex;
}

.row h3{
color: whitesmoke;
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.traits-section {
display: grid;
grid-template-columns: repeat(3, minmax(244px, 1fr)); /* Adjust minmax to your preferred size */
gap: 10px; /* Adjust the gap size to your preference */
padding: 10px; /* Add padding if necessary */
}

.trait-pill {
background: #333; /* Your pill background color */
color: white; /* Your pill text color */
border-radius: 8px; /* Rounded corners for the pill */
padding: 8px; /* Padding inside the pill */
display: flex;
flex-direction: column; /* Stack trait type and value */
justify-content: center; /* Center content vertically */
align-items: left; /* Center content horizontally */
text-align: left; /* Center text */
}

.details-trait-type {
font-weight: bold; /* Make trait type bold */
margin-bottom: 4px;
font-size: 1.1em; /* Space between trait type and value */
}

.details-trait-value {
font-size: 1em; /* Smaller font size for trait value */
}

.rank, .current-price {
margin-top: 10px;
}

.container-fluid {
background-color: #121212;
}

.details-trait-type {
font-weight: bold;
margin-bottom: 5px;
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.details-trait-value {
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.card-rank {
position: flex;
text-align: right;
background-color: rgba(0, 0, 0, 0.9);
color: whitesmoke;
padding: 10px 10px;
margin-top: 5px;
border-radius: 10px;
font-size: 14px;
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.nft-listed-pill-modal {
position: flex;
text-align: right;
padding: 10px 10px;
margin-top: 5px;
border-radius: 10px;
font-size: 14px;
font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}